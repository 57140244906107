.topbar {
  width: 100%;
  background-color: white;
  padding: 1rem 7rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 1;
  box-sizing: border-box;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.access-button {
  padding: 0.75rem;
  border-radius: 4px;
  border: none;
  background-color: #2c5fd1;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 900;
}

@media (max-width: 1199px) {
  .topbar {
    padding: 2rem 4rem;
  }
}

@media (max-width: 991px) {
  .topbar {
    padding: 1.5rem 1.5rem;
  }

}

@media (max-width: 767px) {
  .topbar {
    padding: 1rem ;
    flex-wrap: wrap;
    justify-content: center;
  }

  .logo {
    width: 50%;
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .access-button {
    margin: 1rem 0 0;
    width: 70%;
  }
}

.logo {
  width: 200px;
}

