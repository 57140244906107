.brands {
  background-color: rgba(163, 200, 255, 0.11);
  padding: 5% 0 7.5%;
}

.brand-heading {
  font-size: 1.5rem;
  text-align: center;
  margin: 2% 0;
  color: #343434;
  font-weight: 500;
}

.brands-div {
  margin: 4% 5%;
  display: flex;
  justify-content: space-around;
}

.brands-div > img {
  max-width: 15%;
  height: auto;
  object-fit: contain;
}
@media only screen and (max-width: 700px) {
  .brands {
    padding: 10% 1rem;
  }

  .brand-heading {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
  }

  .brands-div {
    margin: 0%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .brands-div > img {
    max-width: calc(50% - 1rem);
    margin-top: 10%;
  }
}

