.Apps-main {
  margin: 2rem;
}

.Apps {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

.dropdowns {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.dropdowns > div {
  display: flex;
  flex-wrap: wrap;
}

.dropdown-heads {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.dropdown-heads > p {
  background-color: #2e7ff8;
  padding: 0.5rem;
  color: white;
  border-radius: 1.25rem;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-heads > h5 {
  color: #999999;
  font-weight: 700;
  font-size: 0.9rem;
  cursor: pointer;
}

.card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2.5rem;
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 2px;
}

.card-container > img {
  width: 100%;
  object-fit: contain;
}

.content-top {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.content-top > h2 {
  font-size: 1.2rem;
  color: #081f32;
}

.content-icons {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.contents-middle > p {
  font-size: 0.9rem;
  color: #6e798c;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.contents-middle-line {
  font-size: 12px;
  font-weight: 600;
  color: black;
}

.app-feature {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.app-feature > p {
  font-size: 0.9rem;
  color: #a5adbb;
}

.contents-bottom {
  display: flex;
  margin-top: 0.5rem;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
}

.contents-bottom > p {
  font-size: 0.75rem;
  color: #2e7ff8;
  font-style: italic;
}

.contents-bottom > h3 {
  font-size: 1rem;
  font-weight: 600;
  color: black;
}

.contents-bottom > button {
  background-color: #2e7ff8;
  border: none;
  color: white;
  padding: 7.5px;
  border-radius: 10px;
  cursor: pointer;
}

.know-more {
  padding: 6px 12px;
  background-color: #2e7ff8;
  border: none;
  color: white;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 1.5rem;
}

/* Media Queries for Mobile Devices */

@media only screen and (max-width: 500px) {
  .Apps-main{
    margin: 0;
  }
  .Apps {
    grid-template-columns: 1fr;
  }

  .dropdowns {
    gap: 0.5rem;
  }

  .dropdown-heads > h5 {
    font-size: 0.8rem;
  }

  .card-container {
    padding: 0.5rem;
  }

  .content-top > h2 {
    font-size: 1rem;
  }

  .contents-middle > p {
    font-size: 0.8rem;
  }

  .contents-middle-line {
    font-size: 10px;
  }

  .app-feature > p {
    font-size: 0.8rem;
  }

  .contents-bottom > p {
    font-size: 0.7rem;
  }

  .contents-bottom > h3 {
    font-size: 0.9rem;
  }

  .contents-bottom > button {
    padding: 5px;
    font-size: 0.7rem;
  }

  .know-more {
    padding: 4px 8px;
    font-size: 0.8rem;
  }
}
