.footer-main {
  height: 100px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  justify-content: space-between;
  padding: 0 3rem;
  box-sizing: border-box;
}

.footer-main > img {
  height: 30px;
  margin-left: 3.5rem;
}
.footer-contributors{
  margin: 0;
  font-size: 1rem;
}
.social-media {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-right: 3rem;
}

.social-media svg {
  height: 24px;
  width: 24px;
}

@media only screen and (max-width: 768px) {
  .footer-main {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    height: auto;
  }
  .footer-main > img {
    margin: 0;
    margin-bottom: 1rem;
  }
  .social-media {
    margin: 0;
    margin-top: 2rem;
  }
}
