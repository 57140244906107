.Buckets-main > h1 {
  font-size: 2.5rem;
  color: #00255b;
  text-align: center;
  margin-top: 2.5rem;
}

.Buckets {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1rem;
}

.bucket-card {
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 16px;

  max-width: 100%;
  margin: 2.5rem;
  cursor: pointer;
}

.bc-left {
  width: 30%;
  background-color: #e5ebff;
}

.bc-right {
  width: 70%;
  padding: 1rem;
}

.bc-right-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bc-right-top > h3 {
  font-size: 1.1rem;
  color: #3a3a3a;
}

.bc-right-top > p {
  font-size: 0.9rem;
  color: #2e7ff8;
}

.bc-right-middle {
  margin-top: 1rem;
}

.bc-right-middle > p {
  font-size: 0.85rem;
  color: #4a4a4a;
}

.bc-right-bottom {
  margin-top: 1rem;
}

.bc-right-bottom > h3 {
  font-size: 0.85rem;
  font-weight: 600;
}

.bc-investment-options {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bc-investment-options > div {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.bc-investment-options > div > p {
  font-size: 1rem;
  color: #636161;
}

.avatar {
  position: relative;
  display: inline-block;
  box-shadow: rgba(0, 0, 0, 0.24) 2px 2px 2px;
  border-radius: 50%;

}

.img-circle-small {
  width: 53px;
  height: 55px;
  border-top-left-radius: 50% 50%;
  border-top-right-radius: 50% 50%;
  border-bottom-right-radius: 50% 50%;
  border-bottom-left-radius: 50% 50%;
  margin-bottom: 2px;
}

.company {
  width: 16px;
  height: 16px;
  border-top-left-radius: 50% 50%;
  border-top-right-radius: 50% 50%;
  border-bottom-right-radius: 50% 50%;
  border-bottom-left-radius: 50% 50%;
  border: 2px solid #ccc;
  margin-bottom: 2px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.tab-container {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}

/* Style for the tab buttons */
.tab-button {
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  color: #333;
  border: none;
  background-color: white;
  font-size: 1.5rem;

}

/* Style for the active tab button */
.tab-button.active {
  border-bottom: 3px solid #007bff;
  color: #333;
  font-weight: 800;
}

@media screen and (max-width: 768px) {
  .tab-button {

    font-size: 1rem;
  
  }
  
}



/* Media queries for responsive design */

@media screen and (max-width: 768px) {
  .bucket-card {
    flex-basis: 100%;
    max-width: 100%;
    margin: 1rem;
    height: auto;
    flex-direction: column;
    align-items: center;
  }
  .Buckets-main > h1 {
    font-size: 1.5rem;
  }

  .bc-left {
display: none;   
  }

  .bc-right {
    width: 100%;
    padding: 1rem;
  }
}
