.bucket-card {
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 16px;
  max-width: 700px;
  margin: 2.5rem;
  cursor: pointer;
  height: 275px;
}

.bc-left {
  width: 30%;
  background-color: #e5ebff;
  border-radius: 16px 0px 0px 16px;
}
.bc-left>img{
  width: 100%;
  height: 100%;
  border-radius: 16px 0px 0px 16px;
}
.bc-right {
  width: 70%;
  padding: 1rem;
}
.bc-right-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bc-right-top > h3 {
  font-size: 1.1rem;
  color: #3a3a3a;
}
.bc-right-top > p {
  font-size: 0.9rem;
  color: #2e7ff8;
}
.bc-right-middle {
  margin-top: 1rem;
}
.bc-right-middle > p {
  font-size: 0.85rem;
  color: #4a4a4a;
}
.bc-right-bottom {
  margin-top: 1rem;
}
.margin-top{
  margin-top: 3rem;
}
.bc-right-bottom > h3 {
  font-size: 0.85rem;
  font-weight: 600;
}
.bc-investment-options {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bc-investment-options > div {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}
.bc-investment-options > div > p {
  font-size: 0.9rem;
  color: #636161;
}
.avatar {
  position: relative;
  display: inline-block;
  width: 65px; 
  height: 65px;
}
.img-circle-small {
  width: 100%;
  height: 100%;
  object-fit: cover;
border-radius: 50%;
}
.company {
  width: 16px;
  height: 16px;
  border-top-left-radius: 50% 50%;
  border-top-right-radius: 50% 50%;
  border-bottom-right-radius: 50% 50%;
  border-bottom-left-radius: 50% 50%;
  border: 2px solid #ccc;
  margin-bottom: 2px;
  position: absolute;
  bottom: 0;
  right: 0;
}
