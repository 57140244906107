.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -1.25rem;
  width: calc(30% - 2.5rem);
  min-height: 650px;
}
.contents{
  margin-top: 1.25rem;
  padding: 2rem;
}

.app-card {
  border-radius: 12px;
  background-color: #F9F9F9;
}

.app-card > img {
  width: 100%;
  object-fit: contain;
  border-radius: 0.75rem 0.75rem 0rem 0rem;
}

.content-top {
  display: flex;
  align-items: center;
  gap: 2.5rem;
}

.content-top > h2 {
  font-size: 1.5rem;
  color: #081f32;
}

.content-icons {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.contents-middle > p {
  font-size: 1rem;
  color: #6e798c;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}
.contents-middle-line{
  font-size: 14px;
  font-weight: 600;
  color: black;
}
.app-feature {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}

.app-feature > p {
  font-size: 1rem;
  color: #a5adbb;
}

.contents-bottom {
  display: flex;
  margin-top: 0.75rem;
  align-items: center;
  justify-content: space-between;
  gap: 2.25rem;
}

.contents-bottom > p {
  font-size: 0.75rem;
  color: #2E7FF8;
  font-style: italic;
}

.contents-bottom > h3 {
  font-size: 1rem;
  font-weight: 600;
  color: black;
}

.contents-bottom > button {
  background-color: #2e7ff8;
  border: none;
  color: white;
  padding: 7.5px;
  border-radius: 10px;
  cursor: pointer;
}

.know-more{
  padding: 6px 12px;
  background-color: #2e7ff8;
  border: none;
  color: white;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 2.25rem;
}
