.story {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5% 10% 0;
  padding: 5% 0;
}

.story-image {
  width: 40%;
  max-width: 500px;
  margin-top: -10%;
  margin-right: 5%;

}

.content {
  width: 55%;
}

.text-content > h1 {
  font-size: 2rem;
  font-weight: 700;
  color: #343434;
  margin-bottom: 1rem;
}

.text-content > p {
  font-size: 1rem;
  font-weight: 500;
  color: #676767;
  line-height: 29px;
  margin-bottom: 2rem;
}

.read-more {
  color: #2c5fd1;
  cursor: pointer;
  text-decoration: none;
  font-weight: 600;
}

.read-more:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 700px) {
  .story {
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 10% 5%;
  }

  .story-image {
    width: 100%;
    margin-right: 0;
    margin-bottom: 5%;
  }

  .content {
    width: 100%;
    margin: 0;
    text-align: center;
  }
}
