.banner {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: 0%;
  align-items: center;
  box-sizing: border-box; 
  height: 100vh;

}
.banner-left {
  width: 50%;
  padding-left: 5%;
}

.banner-heading {
  font-size: 4rem;
  font-weight: 900;
  color: #010101;
}

.banner-sub {
  font-size: 1.5rem;
  font-weight: 500;
  color: #676767;
  margin-top: 1.5rem;
  line-height: 1.5rem;
  width: 100%;
}
.banner-buttons>form {
  margin-top: 2.5rem;
  display: flex;
  align-items: center;
  gap: 2.5rem;
  width: 100%;
}
.banner-buttons>form>input {
  padding: 1.1rem;
  background: #F2F2F2;
  width: 60%;
  border: 0;
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 400;

}
.banner-button-1 {
  padding: 0.95rem;
  border-radius: 4px;
  border: none;
  background-color: #2c5fd1;
  color: white;
  cursor: pointer;
  font-size: 1.25rem;
  font-weight: 900;
}

.banner-button-2 {
  padding: 0.75rem;
  border-radius: 4px;
  border: 1px solid #2f80ed;
  background-color: white;
  color: #2f80ed;
  cursor: pointer;
}

.banner-right {
  width: 45%;
}
.fade-in-out {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  margin-top: 1rem;
  background-color: #F3FBFF;
  color: black;
  font-weight: bold;
  font-size: 1rem;
  padding: 1rem;
  width: 60%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
}

.fade-in-out.show {
  opacity: 1;
  height: 1.5rem;

}
.fade-in-out.warning {
  opacity: 1;
}
.fade-in-out2 {
  opacity: 1;
  margin: 1rem auto;
  background-color: #F3FBFF;
  color: black;
  font-weight: bold;
  font-size: 1rem;
  padding: 1rem;
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}


.banner-image-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-image {
  width: 75%;
  height: 75%;
  object-fit: cover;
}


@media only screen and (max-width: 700px) {
  .banner{
    padding: 1rem;
  height: 70vh;
  }
  .banner-button-1{
    padding: 0.75rem;
    border-radius: 4px;
    border: none;
    background-color: #2c5fd1;
    color: white;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 900;
  }
  .banner-right {
    display: none;
  }
  .banner-left {
    width: 100%;
    padding-left: 0%;
  }
  .banner-heading {
    font-size: 2rem;
    font-weight: bolder;
    text-align: center;
    margin-top: 1rem;
  }
  .banner-sub {
    width: 100%;
    font-size: 1rem;
    font-weight: bold;
    margin-top: 1.5rem;
    text-align: center;

  }
  .banner-buttons {
    margin-top: 1.25rem;
    gap: 1.25rem;
  }
  .banner-buttons>form{
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .banner-buttons>form>input {
    width: 80%;
  }
  .banner-button-1 {
    width: 70%;
  }

  .banner-button-2 {
    padding: 0.5rem;
  }
  .fade-in-out {
    width: 80%;
    justify-content: center;
    text-align: center;

  }
}
