.newsletter {
  background-color: #f2f7ff;
  padding: 7.5% 2.5%;
  text-align: center;
}

.newsletter-heading {
  font-size: 2.25rem;
  font-weight: 700;
  color: #343434;
}

.newsletter-subheading {
  color: #4b4a4a;
  font-size: 1rem;
  line-height: 22px;
  margin: 1rem;
}

.newsletter-div>form {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
}

.newsletter-div >form> input {
  padding: 1rem;
  background-color: white;
  border: 1px solid #cccccc;
  border-radius: 4px;
  width: 350px;
  font-weight: 400;
  font-size: 1rem;
}

.newsletter-div >form> button {
  background-color: #2C5FD1;
  padding: 1rem;
  border-radius: 4px;
  font-weight: 900;
  border: none;
  color: white;
  cursor: pointer;
}

@media only screen and (max-width: 700px) {
  .newsletter-heading{
    font-size: 1.75rem;
  }
  .newsletter-subheading{
    font-size: 0.75rem;
  }
  .newsletter-div>form{
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .newsletter-div >form> input {
    max-width: 300px;
  }
  .newsletter-div >form> button {
    width: 60%;
  }
}