.card {
  height: auto;
  width: 350px;
  padding: 20px;
  font-family: segoe ui;
  border: solid 2px #f2f2f2;
  text-align: inherit;
  margin: 20px;
  background-color: #ffffff;
  box-shadow: 2px 2px 4px #f2f2f2;
  border-radius: 8px;
}
