.feature {
  margin-top: 5rem;
}

.feature-head {
  font-size: 2.25rem;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.8);
  width: 60%;
  margin: 0 auto;
  text-align: center;
}

.feature-sub {
  color: #999999;
  font-size: 1rem;
  width: 40%;
  margin: 0 auto;
  margin-top: 2%;
  text-align: center;
}

.features {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5% 10%;
  flex-wrap: wrap;
}

.feature-block {
  margin: 3rem;
  flex: 1 0 15%;
}

.feature-block > h1 {
  margin-top: 3%;
  font-size: 1.5rem;
  font-weight: 700;
  color: #363940;
}

.feature-block > p {
  margin-top: 3%;
  font-size: 0.9rem;
  line-height: 1.5rem;
  font-weight: 500;
  color: #999999;
}

@media only screen and (max-width: 700px) {
  .feature {
    margin-top: 5%;
  }

  .feature-head {
    font-size: 1.5rem;
    font-weight: 350;
    width: 90%;
  }
  
  .feature-sub {
    font-size: 1rem;
    width: 80%;
    margin-top: 5%;
    line-height: 1.5rem;
  }
  
  .features {
    padding: 5% 5%;
    align-items: flex-start;
  }
  
  .feature-block {
    margin: 1rem;
    flex: 1 0 30%;
  }
}
