.figure {
  margin-top: 7.5%;
}

.figures-head {
  font-size: 2.25rem;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.8);
  width: 50%;
  margin: 0 auto;
  text-align: center;
}

.figures-sub {
  color: #999999;
  font-size: 1rem;
  width: 40%;
  margin: 0 auto;
  margin-top: 1.5%;
  text-align: center;
}

.figures {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5% 10%;
  flex-wrap: wrap;
}

.figures-block {
  margin: 3rem;
  flex: 1 0 15%; /* explanation below */
}

.figures-block > h1 {
  margin-top: 3%;
  font-size: 40px;
  font-weight: 700;
  color: #363940;
}

.figures-block > p {
  margin-top: 3%;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  color: #999999;
}

@media only screen and (max-width: 700px) {
  .figure {
    margin: 10%;
  }
  .figures-head {
    font-size: 1.5rem;
    font-weight: 350;
    width: 100%;
  }

  .figures-sub {
    font-size: 1rem;
    width: 100%;
    margin-top: 5%;
  }
  .figures-block {
    margin: 2rem;
  }
  .figures-block > h1 {
    margin-top: 3%;
    font-size: 30px;
    font-weight: 350;
  }

  .figures-block > p {
    margin-top: 3%;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
    color: #999999;
  }
}
