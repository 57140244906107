.services {
  background-color: #f2f7ff;
  padding-top: 3%;
  padding-bottom: 7.5%;
}
.services-heading {
  font-size: 2.5rem;
  text-align: center;
  color: #343434;
  font-weight: 700;
}
.services-subheading {
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  margin-top: 1%;
  color: #999999;
}

.services-div {
  margin-top: 4%;
  display: flex;
  justify-content: space-around;
  margin-left: 5%;
  margin-right: 5%;
}
.services-div > div {
  width: 15%;
}
.services-div > div > img {
  object-fit: contain;
}
.services-div > div > h3 {
  font-size: 1.3rem;
  font-weight: 900;
  color: #363940;
  margin-top: 5%;
  cursor: pointer;
  line-height: 2rem;
}

.services-div > div > h3:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 700px) {
  .services {
    display: none;
  }
}
