.Apps-main {
  margin: 7.5rem;
}

.Apps {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;
  gap: 5rem;
  margin-top: 2.5rem;
}

.risk {
  border: 1px solid #2E538A;
  background-color: rgba(192, 217, 255, 0.259);
  padding: 4px 8px 4px 12px;
  margin-right: 4px;
  border-radius: 20px;
}

.return {
  border: 1px solid #2E538A;
  background-color: rgba(192, 217, 255, 0.259);
  padding: 4px 8px 4px 12px;
  border-radius: 20px;


}

.apps-searchbar {
  margin-left: 7.5rem;
}

.apps-searchbar>input {
  padding: 12px 15px;
  background-color: white;
  border: 1px solid #cccccc;
  border-radius: 4px;
  width: 80%;
}

.apps-searchbar>button {
  background-color: #2e7ff8;
  border: none;
  color: white;
  padding: 12.5px;
  width: 150px;
  cursor: pointer;
}

.dropdowns {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.dropdowns>div {
  display: flex;
}

.dropdown-heads {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.dropdown-heads>p {
  background-color: #2e7ff8;
  padding: 0.5rem;
  color: white;
  border-radius: 1.25rem;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.dropdown-heads>h5 {
  color: #999999;
  font-weight: 700;
  font-size: 0.9rem;
  cursor: pointer;
}

@media only screen and (max-width: 1550px) {
  .Apps-main {
    margin: 2.5rem;
  }
}

@media only screen and (max-width: 750px) {
  .Apps-main {
    margin: 0;
  }

  .Apps {
    display: flex;
    flex-direction: column;
    margin-top: 0rem;
  }

  .card-container {
    width: 80% !important;
  }

  .dropdowns {
    padding: 2rem;
  }
}