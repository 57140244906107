.how-it-works-container {
  display: flex;
  justify-content: center;
  box-sizing: content-box;
}
.how-it-works-heading-div{
  background-color: #F6FAFF;
width: 100%;
}
.how-it-works-heading{
  text-align: center;
  font-size: 3rem;
  font-weight: 600;
  margin:  2rem 0rem;
}
.how-it-works-steps {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
.text-blue{
  color: #085CDA;
  text-decoration: none;
}
.how-it-works-step-1 {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
  position: relative;
  background-color: #F6FAFF;

}
.how-it-works-step-2 {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
  position: relative;
  background-color: white;

}
.how-it-works-step-3 {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
  position: relative;
  background-color: #F6FAFF;

}
.step-image1 {
  width: 60%;
  padding: 5rem 3rem;
  display: flex;
  justify-content: flex-end;
}

.step-image2 {
  width: 40%;
  padding: 5rem 3rem;
  display: flex;
  justify-content: flex-start;
}

.step-content-1 {
  width: 40%;
  text-align: left;
  margin-top: 20px;
  padding: 5rem;
  font-family: 600;
}

.step-content-2 {
  width: 60%;
  margin-top: 20px;
  padding: 5rem;
  font-family: 600;
}



.step-content-1 > p {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2.75rem;
}

.step-content-1 > h3 {
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 2.5rem;
  margin-bottom: 2rem;
}

.step-content-2 > p {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2.75rem;
}

.step-content-2 > h3 {
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 2.5rem;
  margin-bottom: 2rem;
}
@media (min-width: 1600px) {
  .step-image1 {
    width: 50%;
    padding: 5rem 3rem;
    display: flex;
    justify-content: flex-end;
  }
  
  .step-image2 {
    width: 50%;
    padding: 5rem 3rem;
    display: flex;
    justify-content: flex-start;
  }
  
  .step-content-1 {
    width: 50%;
    text-align: left;
    margin-top: 20px;
    padding: 5rem;
    font-family: 600;
  }
  
  .step-content-2 {
    width: 50%;
    margin-top: 20px;
    padding: 5rem;
    font-family: 600;
  }
  

}
@media (max-width: 767px) {
  .how-it-works-steps{
    display: flex;
    flex-direction: column;
    width: 100%;

  }
  .how-it-works-heading{
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 2rem;
  }
  .how-it-works-step-1{
    display: flex;
    flex-direction: column;
  }
  .step-content-1{
    width: 100%;
    text-align: center;
    margin-top: 20px;
    padding: 0rem;
    font-size: 1.75rem;
  }
  .step-content-2{
    width: 100%;
    text-align: center;
    margin-top: 20px;
    padding: 0rem;
    font-size: 1.75rem;
  }
  .step-content-1 > p {
    font-size: 1.25rem;
    line-height: 2rem;
    padding: 2rem;
    text-align: justify;
  }
  .step-content-1 > h3 {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 0rem;
  }
  .step-content-2 > p {
    font-size: 1.25rem;
    line-height: 2rem;
    padding: 2rem;
    text-align: justify;


  }
  .step-content-2 > h3 {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 0rem;
  }
  .step-image1{
    display: flex;
    justify-content: center;
    padding: 0rem;
    width: 100%;
  }
  .step-image1>img{
    width: 100%;
    max-width: 350px;
  }
  .step-image2{
    display: flex;
    justify-content: center;
    padding: 0rem;
    width: 100%;
  }
  .step-image2>img{
    width: 100%;
    max-width: 350px;
  }
  .step-image3{
    display: flex;
    justify-content: center;
    padding: 0rem;
    width: 100%;
  }
  .step-image3>img{
    width: 100%;
    max-width: 350px;
  }
  .how-it-works-step-2{
    display: flex;
    flex-direction: column-reverse;
  }
  .how-it-works-step-3{
    display: flex;
    flex-direction: column;
    padding: 2rem 0rem 0rem 0rem;
  }

}
